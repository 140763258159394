<template>
  <div class="custom-table-wrapper">
    <table class="custom-table">
      <thead>
      <tr class="head-custom-table">
        <td v-for="(heading, keyName, index) in options.headings"
            :key="index"
            :class="`head-${keyName}`"
            @click="checkSorting(keyName)"
            class="cell-custom-table">
          {{ heading }}
          <slot :name="`head${keyName}`"></slot>

          <!-- Sort indication -->
          <div v-if="options.sortable[keyName]" class="sort-toggle">
            <img src="../../../assets/icons/svg/ic_dropup_active.svg"
                 :class="{'icon-active': selectedSortingId === options.sortable[keyName][0].id}"
                 class="caret-icon"
                 alt="caret"/>
            <img src="../../../assets/icons/svg/ic_dropdown_active.svg"
                 :class="{'icon-active': selectedSortingId === options.sortable[keyName][1].id}"
                 class="caret-icon"
                 alt="caret"/>
          </div>
        </td>
      </tr>
      </thead>
      <tbody v-if="tableData">
      <tr v-for="rowData in tableData"
          :key="rowData.id"
          :class="getRowClasses(rowData.id)"
          :id="`row-${rowData.id}`"
          class="row-custom-table">
        <td v-for="(heading, keyName, index) in options.headings"
            :key="index"
            :id="`cell-${keyName}-${rowData.id}`"
            :class="[getBorderedCellStyling(keyName, rowData.id)]"
            @mouseover="setHover(keyName, rowData.id)"
            @mouseleave="removeHover(keyName, rowData.id)"
            class="cell-custom-table">
          <slot :name="keyName" :rowData="rowData"></slot>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {ref} from '@vue/composition-api';

export default {
  name: "CustomTable",
  props: {
    options: {
      required: true
    },
    tableData: {
      required: true
    },
    sortingFunction: {
      type: Function,
    },
    selectedRows: {
      type: Array,
      required: false
    },

    // Open details:
    // When the user opens the details of a row, the row should have a black border.
    rowsWithExpandedDetails: {
      type: Array,
      required: false
    },

    // Selected cell:
    // When the user starts editing a cell, the cell should have a border.
    borderedCell: {
      type: Object,
      required: false,
      default: null
    },
  },
  setup(props) {
    function isRowSelected(id) {
      return props.selectedRows ? props.selectedRows.find(row => id === row.id) : false;
    }

    function hasExpandedDetails(id) {
      return props.rowsWithExpandedDetails ? props.rowsWithExpandedDetails.find(row => id === row.id) : false;
    }

    function getRowClasses(id) {
      if (hasExpandedDetails(id)) {
        return 'has-expanded-details';
      } else if (isRowSelected(id)) {
        return 'selected-row';
      }
      return '';
    }

    /** Bordered cell styling **/
    function getBorderedCellStyling(keyName, cellId) {
      if (props.borderedCell?.cellKey === keyName && props.borderedCell?.rowId === cellId) {
        return 'bordered-cell';
      }
      return '';
    }

    /** Hover cell styling **/
    const setHover = (keyName, id) => {
      // The cell should have a hover state if the cell is editable AND the edit is not active on that cell.
      const isEditable = props.options.editable[keyName];
      const isEditActive = props.borderedCell?.cellKey === keyName && props.borderedCell?.rowId === id;
      if (!isEditable || isEditActive) {
        return;
      }
      document.getElementById(`cell-${keyName}-${id}`).classList.add('is-editable');
    }

    const removeHover = (keyName, id) => {
      const isEditable = props.options.editable[keyName];
      if (!isEditable) {
        return;
      }
      document.getElementById(`cell-${keyName}-${id}`).classList.remove('is-editable');
    };

    /** Sorting **/
    const selectedSortingId = ref('');
    const checkSorting = (keyName) => {
      if (props.options.sortable?.[keyName]) {
        // update the arrow status
        setSelectedSortingId(keyName);

        // send the option outside to do remove or local sorting
        const option = props.options.sortable[keyName].find(item => item.id === selectedSortingId.value)
        props?.sortingFunction(option);

      }
    };

    const setSelectedSortingId = (keyName) => {
      if (!selectedSortingId.value) {
        selectedSortingId.value = props.options.sortable[keyName][0].id;
      } else {
        const newId = selectedSortingId.value === props.options.sortable[keyName][0].id ? 1 : 0;
        selectedSortingId.value = props.options.sortable[keyName][newId].id
      }
    }

    return {
      hasExpandedDetails,
      getRowClasses,

      /** Bordered cell styling **/
      getBorderedCellStyling,

      /** Hover cell styling **/
      setHover,
      removeHover,

      /** Sorting **/
      selectedSortingId,
      checkSorting,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.custom-table-wrapper {
  margin: rem(-30);
  padding: rem(30);
}

.sort-toggle {
  @include hover-active-pointer;
  display: inline-block;
  font-size: rem(14);
  height: rem(12);
  position: relative;
  text-align: center;
  width: rem(20);

  .caret-icon {
    display: inline-block;
    height: rem(20);
    opacity: 0.2;
    width: rem(20);

    &:first-child {
      @include position(absolute, $bottom: 20%);
    }

    &:last-child {
      @include position(absolute, $top: 0);
    }
    &.icon-active {
      opacity: 1;
    }
  }
}

/* table styling */
.custom-table {
  border: rem(1) solid var(--gray-main);
  border-radius: rem(10);
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.head-custom-table {
  border: rem(1) solid var(--gray-main);
  font-size: rem(17);
  font-weight: bold;

  .cell-custom-table {
    background-color: var(--gray-main);

    &:first-child {
      border-top-left-radius: rem(10);
    }

    &:last-child {
      border-top-right-radius: rem(10);
    }
  }
}

.row-custom-table {
  margin-bottom: rem(10);
  vertical-align: middle;

  &:not(:last-child) {
    border-bottom: rem(1) solid var(--gray-main);
  }

  &:last-child {
    border-bottom-left-radius: rem(10);
    border-bottom-right-radius: rem(10);

    .cell-custom-table {

      // The first cell of the last row needs to have a border left radius.
      &:first-child {
        border-bottom-left-radius: rem(10);
      }

      // The last cell of the last row needs to have a border left radius.
      &:last-child {
        border-bottom-right-radius: rem(10);
      }
    }
  }

  .cell-custom-table {
    border-bottom: rem(1) solid var(--gray-main);
  }
}

.cell-custom-table {
  padding: rem(13);
  height: rem(30);
  width: 5%;
}

.cell-custom-table > * {
  vertical-align: middle;
}

.selected-row {
  background-color: var(--red-light-02);
  position: relative;

  // Add the red border to the first cell of the row.
  .cell-custom-table {
    &::before {
      @include position(absolute, $top: 0, $bottom: 0, $left: 0);
      background-color: var(--red-main);
      content: "";
      width: rem(3);
    }
  }
}

.has-expanded-details {
  background-color: var(--red-light-02);

  .cell-custom-table {
    &:first-child {
      border-left: rem(1) solid var(--black-light);
      border-top: rem(1) solid var(--black-light);
    }

    &:not(:first-child) {
      border-top: rem(1) solid var(--black-light);
    }

    &:last-child {
      border-right: rem(1) solid var(--black-light);
    }
  }
}


// Editable cell
.is-editable {
  @include hover-active-pointer {
    border: rem(1) solid var(--gray-light-02);
    box-shadow: 2px 4px 8px 0 rgba(70, 70, 70, 0.1);
  }
}

/* your custom styling comes here */
.action-button-group {
  display: flex;
  justify-content: flex-end;
}

/** Project overview style **/
.project-overview-table {
  .head-name {
    width: 90%;
  }

  .head-action {
    width: 10%;
  }
}

.logs-table {
  text-align: left;

  // Header
  .head-checkbox {
    padding: 0 0 0 rem(18);
    width: 5%;
  }

  .head-date {
    width: 10%;
  }

  .head-logId {
    width: 10%;
  }

  .head-employee {
    width: 10%;
  }

  .head-issue {
    width: 10%;
  }

  .head-summary {
    width: 20%;
  }

  .head-comment {
    width: 25%;
  }

  .head-hours {
    width: 5%;
  }

  .head-ratio {
    width: 5%;
  }

  // Rows
  .row-custom-table {
    .cell-custom-table {
      &:first-child {
        padding: 0 0 0 rem(18);
      }
    }
  }

  // Cells
  .bordered-cell {
    border: rem(1) solid var(--black-light);
    box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
    padding: rem(14) rem(20);
  }
}

.order-logs-table {
  text-align: left;

  // Header
  .head-checkbox {
    padding: 0 0 0 rem(18);
    width: 5%;
  }

  .head-date {
    width: 10%;
  }

  .head-employee {
    width: 10%;
  }

  .head-issue {
    width: 10%;
  }

  .head-summary {
    width: 20%;
  }

  .head-comment {
    width: 25%;
  }

  .head-exportPreset {
    width: 15%;
  }

  .head-hours {
    text-align: center;
    width: 5%;
  }

  .head-ratio {
    text-align: center;
    width: 5%;
  }

  // Rows
  .row-custom-table {
    .cell-custom-table {
      &:first-child {
        padding: 0 0 0 rem(18);
      }
    }
  }

  // Cells
  .bordered-cell {
    border: rem(1) solid var(--black-light);
    box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
    padding: rem(14) rem(20);
  }
}

// Fixed/floating
.floating-table {
  thead tr {
    @include position-elevation(sticky, $top: rem(130), $elevation: var(--fixed-table-head-index));
    box-shadow: 0 rem(2) rem(10) 0 rgba(0,0,0,0.05);
  }
}

.conflict-logs-table {
  text-align: left;

  // Header
  .head-checkbox {
    width: 5%;
  }

  .head-date {
    width: 10%;
  }

  .head-order {
    width: 20%;
  }

  .head-employee {
    width: 10%;
  }

  .head-changes {
    width: 35%;
  }

  .head-actions {
    width: 15%;
  }
}

.conflict-issues-table {
  text-align: left;

  // Header
  .head-checkbox {
    width: 5%;
  }

  .head-date {
    width: 15%;
  }

  .head-orders {
    width: 30%;
  }

  .head-issue {
    width: 10%;
  }

  .head-changes {
    width: 25%;
  }

  .head-actions {
    width: 15%;
  }
}

.active-orders-table {
  text-align: left;

  .head-name {
    width: 35%;
  }

  .head-orders {
    width: 65%;
  }

  .row-custom-table {
    .cell-custom-table {

      // Project name
      &:first-child {
        vertical-align: baseline;
      }
    }
  }
}

.processed-logs-table {
  text-align: left;

  .head-checkbox {
    width: 5%;
  }

  .head-date {
    width: 10%;
  }

  .head-employee {
    width: 15%;
  }

  .head-issue {
    width: 15%;
  }

  .head-summary {
    width: 25%;
  }

  .head-comment {
    width: 25%;
  }

  .head-hours {
    text-align: center;
    width: 5%;
  }

  // Cells
  .bordered-cell {
    border: rem(1) solid var(--black-light);
    box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
    padding: rem(14) rem(20);
  }
}

.users-table {

  .head-checkbox {
    width: 5%;
  }

  .head-username {
    width: 25%;
  }

  .head-email {
    width: 25%;
  }

  .head-permission {
    width: 25%;
  }

  .head-createdAt {
    width: 15%;
  }

  // Cells
  .bordered-cell {
    border: rem(1) solid var(--black-light);
    box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
    padding: rem(14) rem(20);
  }
}

.afterpay-table {

  .head-project {
    width: 15%;
  }

  .head-order {
    width: 20%;
  }

  .head-contactDtt {
    width: 15%;
  }

  .head-contactClient {
    width: 15%;
  }

  .head-nextPayment {
    width: 20%;
  }

  .head-status {
    width: 15%;
  }
}
</style>
