import {KEYS_SORTING, SORTING_TYPES, SortingOption} from "@/models/SortingHandler";

export default function getAfterpayTableOptions() {
  const tableOptions = {
    headings: {
      'project': 'Project',
      'order': 'Order',
      'contactDtt': 'Contact DTT',
      'contactClient': 'Contact client',
      'nextPayment': 'Next payment',
      'status': 'Status',
    },
    editable: {
      'project': false,
      'order': false,
      'contactDtt': false,
      'contactClient': false,
      'nextPayment': false,
      'status': false,
    },
    sortable: {
      'project': false,
      'order': false,
      'contactDtt': false,
      'contactClient': false,
      'nextPayment': [
        new SortingOption(20, KEYS_SORTING.NEXT_PAYMENT, SORTING_TYPES.DESCENDING),
        new SortingOption(10, KEYS_SORTING.NEXT_PAYMENT, SORTING_TYPES.ASCENDING),
      ],
      'status': false,

    },
  };

  return tableOptions;
}