export const KEYS_SORTING = {

  // Users
  CREATED_AT: 'createdAt',

  // Afterpay
  NEXT_PAYMENT: 'nextPayment'
};

export const SORTING_TYPES = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};


export class SortingOption {
  constructor(id, sortingKey, sortingType) {
    this.id = id;
    this.sortingKey = sortingKey;
    this.sortingType = sortingType;
  }
}