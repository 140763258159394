<template>
  <div>
    <h1 class="main-title page-title">Afterpay</h1>

    <!-- TODO: Afterpay table -->
    <CustomTable :options="tableOptions"
                 :table-data="[]"
                 :sorting-function="() => {}"
                 class="afterpay-table">
      <!-- Body -->
      <template #project>
      </template>
      <template #order>
      </template>
      <template #contactDtt>
      </template>
      <template #contactClient>
      </template>
      <template #nextPayment="props">
        {{ props.rowData.nextPayment }}
      </template>
      <template #status="props">
        {{ props.rowData.status }}
      </template>
    </CustomTable>

    <!-- TODO: Pagination -->
  </div>
</template>

<script>
import getAfterpayTableOptions from "@/utils/tables/afterpayTableHelpers";
import CustomTable from "@/components/partials/table/CustomTable";

export default {
  name: "Afterpay",
  components: {CustomTable},
  setup() {
    return {
      tableOptions: getAfterpayTableOptions(),
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

</style>